import { IDfa, IssueAvailableInvestorsType } from '@dltru/dfa-models'
import { Form, IValues, Step, StepperContext, useEditable } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { setOfferentOffer } from '@store/dfa/ownershipTransfer'

import { DfaAndPriceOffer } from '@pages/OwnershipTransfer/components/DfaAndPriceOffer/DfaAndPriceOffer'

import { FormDataType } from '../DFAOwnershipReceiverForm'

interface IPersonalOffer {
    id?: string
}

export const OfferentOffer: FC<IPersonalOffer> = () => {
    const [form] = Form.useForm<FormDataType>()
    const dispatch = useDispatch()
    const { editable, currentStep } = useContext(StepperContext)
    const [isOfferOpen, setIsOfferOpen] = useState({ dfa: false, money: false })
    const [isOnlyDfa, setIsOnlyDfa] = useState(false)

    const isEdit = useEditable(currentStep, 1, editable)

    const onDfaChangeHelper = (dfa?: IDfa) => {
        const values = { ...form.getFieldsValue() }
        values.offerent.dfa_name = dfa.title
        dispatch(setOfferentOffer({ dfa: { uuid: dfa.id } })) // костыль
        form.setFieldsValue(values)

        if (
            dfa?.decision?.issue_available_investors_type ===
            IssueAvailableInvestorsType.qualified_and_unqualified_with_limit
        ) {
            setIsOnlyDfa(true)
        }

        /*if (
            dfa?.decision?.issue_available_investors_type_limits ===
            IssueAvailableInvestorsType.qualified_and_unqualified_with_limit
        ) {
            setIsOnlyDfa(true)
        }*/
    }

    const onChange = (val: IValues | null) => {
        if (val && 'offerent' in val) {
            if ('total_price' in val.offerent) {
                dispatch(setOfferentOffer({ price: val.offerent.total_price }))
            }

            if ('amount_dfa' in val.offerent) {
                dispatch(setOfferentOffer({ amount_dfa: val.offerent.amount_dfa }))
            }
        }
    }

    const onCloseHandler = (type: 'dfa' | 'money') => {
        if (type === 'money') {
            const values = { ...form.getFieldsValue() }
            values.offerent.total_price = undefined
            form.setFieldsValue(values)
            dispatch(setOfferentOffer({ price: undefined }))
        }

        if (type === 'dfa') {
            const values = { ...form.getFieldsValue() }
            values.offerent.dfa_name = ''
            values.offerent.dfa = ''
            values.offerent.amount_dfa = ''
            form.setFieldsValue(values)
            dispatch(setOfferentOffer({ dfa: {}, amount_dfa: undefined }))
            return
        }
    }

    return (
        <Step
            stepIndex={1}
            onChangeValues={onChange}
            form={form}
            stepTitle="Встречное предоставление Оферента"
            allowEdit={false}
            saveAfterEdit
            disabledHeaderStepCounter={true}
        >
            <DfaAndPriceOffer
                onOpen={(val) => {
                    setIsOfferOpen(val)
                }}
                onClose={onCloseHandler}
                isOfferOpen={isOfferOpen}
                type="offerent"
                isEdit={isEdit}
                onDfaChange={onDfaChangeHelper}
                currentClientType={form.getFieldValue('client_type')}
                currentBaseDescription={form.getFieldValue('base_description')}
                isOnlyDfa={isOnlyDfa}
            />
        </Step>
    )
}
