import { CertificateType, ServiceCodeFee, stringToNumber } from '@dltru/dfa-models'
import {
    Alert,
    Box,
    Button,
    ConditionsModal,
    MiniModal,
    Price,
    QuestionCircle,
} from '@dltru/dfa-ui'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { getTransferFee } from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'
import { moneySelector } from '@store/money/selectors'

import { transferApproveAgreementText } from '@pages/OwnershipTransfer/resources'

import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'
import { WalletWidget } from '@components/WalletWidget'

export interface ITransferApproveModalProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    onOk: (skid: string) => void
    onSignOk: (skid: string) => void
}

export const TransferApproveModal: FC<ITransferApproveModalProps> = ({
    isModalVisible,
    setIsModalVisible,
    onOk,
    onSignOk,
}) => {
    const dispatch = useDispatch()
    const currentOffer = useSelector((state) =>
        dfaTransferSelector.selectOfferByType(state, 'offerent'),
    )
    const accountInfo = useSelector(moneySelector.selectAccount)
    const fee = useSelector(dfaTransferSelector.selectFee)
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const [isConditionModalVisible, setIsConditionModalVisible] = useState(false)
    const totalAmount = useSelector(dfaTransferSelector.selecAllAmountOfferent)
    const [isDisable, setDisable] = useState(false)
    const user_id = useSelector(authSelector.selectUserUid)

    const onSubscribe = (cert: CertificateType) => {
        onOk(cert.skid)
    }

    const onSign = (cert: CertificateType) => {
        onSignOk(cert.skid)
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    useEffect(() => {
        dispatch(
            getTransferFee({
                service_code: ServiceCodeFee.createOffer,
                operation_amount: stringToNumber(totalAmount),
                user_id,
            }),
        )
    }, [totalAmount])

    useEffect(() => {
        if (fee !== undefined && accountInfo?.balance?.available) {
            if (fee + (Number(totalAmount) || 0) > accountInfo?.balance.available) {
                setDisable(true)
            } else {
                setDisable(false)
            }
        } else {
            setDisable(true)
        }
    }, [fee, accountInfo?.balance.available, currentOffer])

    return (
        <>
            <MiniModal visible={isModalVisible} onCancel={handleCancel} width={600}>
                <div>
                    <Box direction="row" align="center">
                        <Box className="modal-content-shift">
                            <QuestionCircle className="modal-icon modal-question-icon" />
                        </Box>
                        <div className="ant-modal-title">Направить оферту</div>
                    </Box>
                    <Box direction="row" align="center" justify="right">
                        <div className="modal-content-shift">&nbsp;</div>
                        <Box padding={[0, 32, 24, 0]}>
                            <p className="modal-content-text">
                                Вы действительно хотите направить Оферту?
                            </p>
                            <p style={{ marginBottom: 0 }}>Комиссия за направление Оферты</p>
                            <Price price={fee ? fee / 100 : 0} />
                            <br />
                            <WalletWidget
                                balance={accountInfo?.balance?.available}
                                account={String(accountInfo?.number)}
                                error={
                                    (accountInfo?.balance?.available || 0) <
                                    (fee || 0) + (Number(totalAmount) || 0)
                                }
                            />
                            <br />
                            <Alert
                                width={456}
                                message="Уважаемый пользователь!"
                                description="Все расчеты по сделке с ЦФА, заключенной посредством направления и акцепта Оферты, осуществляются вне Платформы. Оператор не осуществляет расчетов по данной сделке с ЦФА, не контролирует процесс ее исполнения и не отвечает за неисполнение или ненадлежащее исполнение сторонами обязательств из такой сделки."
                                type="info"
                                showIcon
                            />
                        </Box>
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 0, 24, 0]}
                        direction="row"
                        justify="right"
                    >
                        <Button borderRadius={12} onClick={handleCancel}>
                            Отмена
                        </Button>
                        <Button
                            borderRadius={12}
                            type="primary"
                            onClick={() => setIsVisibleCertificateModal(true)}
                            disabled={isDisable}
                        >
                            Да, направить оферту
                        </Button>
                    </Box>
                    <div className="modal-footer-gray-text">
                        Направляя Оферту, вы соглашаетесь с{' '}
                        <Button type="linkText" onClick={() => setIsConditionModalVisible(true)}>
                            условиями
                        </Button>
                        .
                    </div>
                </div>
            </MiniModal>
            <ConditionsModal
                isModalVisible={isConditionModalVisible}
                setIsModalVisible={setIsConditionModalVisible}
                title="Условия"
                text={transferApproveAgreementText}
            />
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
                onSign={onSign}
            />
        </>
    )
}
