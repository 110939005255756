import {
    BaseDescriptionEnum,
    ClientType,
    ClientTypeTranslate,
    IDFAOwnershipTransferRequestClient,
    ProfileType,
    TransferOwnershipOfferType,
    TransferOwnershipOfferTypeTranslate,
} from '@dltru/dfa-models'
import {
    Divider,
    Form,
    FormItem,
    IValues,
    Radio,
    Space,
    Step,
    StepperContext,
    UploadFile,
    WrappedDateRangeItem,
    WrappedInput,
    WrappedSelect,
    useEditable,
} from '@dltru/dfa-ui'
import { Moment } from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { authSelector } from '@store/auth/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { InnInput, UploadInput } from '@components/Form'

export type FormDataType = {
    amount_dfa: string
    total_price: string
    oferent: string
    ownership_receiver: string
    client_type: IDFAOwnershipTransferRequestClient['buyer_profile_type']
    date: [Moment, Moment]
    base_document_uuid: UploadFile[]
    base_description: BaseDescriptionEnum
}

const clientTypeDescriptionOptions = Object.entries(ClientTypeTranslate).map(([value, label]) => ({
    value,
    label,
}))

export const DFAOwnershipReceiverForm = () => {
    const [form] = Form.useForm<FormDataType>()
    const { editable, currentStep } = useContext(StepperContext)
    const userUuid = useSelector(authSelector.selectUserUid)
    const userInn = useSelector(profileSelector.userInn)
    const userFullName = useSelector(profileSelector.userFullName)

    const [offerType, setOfferType] = useState(TransferOwnershipOfferType.personal)

    const [currentClientType, setCurrentClientType] = useState<ClientType>()

    const isEdit = useEditable(currentStep, 0, editable)

    const oferentRenderUneditable = () => <Link to={`/clients/${userUuid}`}>{userFullName}</Link>

    const onChange = (changedValues: IValues | null) => {
        if (changedValues && 'offer_type' in changedValues) {
            setOfferType(changedValues.offer_type as TransferOwnershipOfferType)
        }

        if (changedValues && 'client_type' in changedValues) {
            setCurrentClientType(changedValues.client_type as ClientType)
            if (
                (currentClientType === ProfileType.LEGL &&
                    changedValues.client_type !== ProfileType.LEGL) ||
                (currentClientType !== ProfileType.LEGL &&
                    changedValues.client_type === ProfileType.LEGL)
            ) {
                form.resetFields(['ownership_receiver'])
            }
        }

        if (changedValues && 'base_description' in changedValues) {
            if (changedValues.base_description === BaseDescriptionEnum.donation_contract) {
                form.setFieldsValue({ total_price: '0' })
            }
        }
    }

    useEffect(() => {
        form.setFieldsValue({ oferent: userFullName })
    }, [userFullName])

    return (
        <Step
            stepIndex={0}
            onChangeValues={onChange}
            form={form}
            stepTitle="Условия оферты"
            allowEdit={false}
            saveAfterEdit
            disabledHeaderStepCounter={true}
        >
            <Divider margin={[8, 0, 32, 0]} />
            <div className="formRow">
                <FormItem name="offer_type" label="Тип оферты">
                    {!isEdit ? (
                        <Radio.Group>
                            <Space direction="vertical">
                                {Object.entries(TransferOwnershipOfferTypeTranslate).map(
                                    ([key, value]) => (
                                        <Radio key={key} value={key}>
                                            {value}
                                        </Radio>
                                    ),
                                )}
                            </Space>
                        </Radio.Group>
                    ) : (
                        <>{TransferOwnershipOfferTypeTranslate[offerType]}</>
                    )}
                </FormItem>
            </div>
            <Divider margin={[8, 0, 32, 0]} />
            <div className="formRow">
                <WrappedInput
                    name="oferent"
                    required
                    disabled
                    label="Оферент"
                    className="formComponentItem"
                    uneditable={isEdit}
                    renderUneditable={oferentRenderUneditable}
                />
            </div>
            {offerType === TransferOwnershipOfferType.personal && (
                <>
                    <Divider margin={[8, 0, 32, 0]} />
                    <div className="formRow">
                        <WrappedSelect
                            name="client_type"
                            required
                            label="Тип пользователя Акцептанта"
                            className="formComponentItem formComponentItem--md"
                            options={clientTypeDescriptionOptions}
                            uneditable={isEdit}
                        />
                    </div>
                    <div className="formRow">
                        <InnInput
                            label="ИНН Акцептанта"
                            name="ownership_receiver"
                            required
                            isUl={currentClientType === ProfileType.LEGL}
                            disabled={!currentClientType}
                            noAvailableInn={userInn}
                            checkInnExistenceFor={currentClientType}
                            uneditable={isEdit}
                        />
                    </div>
                </>
            )}

            <Divider margin={[8, 0, 32, 0]} />

            <div className="formRow">
                <WrappedDateRangeItem
                    className="formComponentItem"
                    name="date"
                    label="Срок действия предложения"
                    required
                    disabled
                    uneditable={isEdit}
                />
            </div>
            <div className="formRow">
                <UploadInput
                    className="formComponentItem upload-form__styled"
                    name="base_document_uuid"
                    label="Загруженные документы"
                    required
                    labelOnlyUneditable={false}
                    uneditable={isEdit}
                    title="Загрузить фотографии документа. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                    maxSizeMb={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                />
            </div>
        </Step>
    )
}
