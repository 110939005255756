import { IDfa, IssueAvailableInvestorsType } from '@dltru/dfa-models'
import { Form, IValues, Step, StepperContext, openMessage, useEditable } from '@dltru/dfa-ui'
import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setAccepterOffer } from '@store/dfa/ownershipTransfer'
import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

import { DfaAndPriceOffer } from '@pages/OwnershipTransfer/components/DfaAndPriceOffer/DfaAndPriceOffer'

interface IPersonalOffer {
    id?: string
}

export const AcceptorOffer: FC<IPersonalOffer> = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const isDfaSelected = useSelector(dfaTransferSelector.isDfaSelected)

    const { editable, currentStep } = useContext(StepperContext)
    const [isOfferOpen, setIsOfferOpen] = useState({ dfa: false, money: false })
    const [isOnlyDfa, setIsOnlyDfa] = useState(false)

    const isEdit = useEditable(currentStep, 2, editable)

    //const allowEdit = currentStep >= stepsTotal && (!editable.length || editable.includes(2))

    const onDfaChangeHelper = (dfa?: IDfa) => {
        const values = { ...form.getFieldsValue() }
        values.acceptor.dfa_name = dfa.title
        dispatch(setAccepterOffer({ dfa: { id: dfa.id } })) // костыль
        form.setFieldsValue(values)

        if (
            dfa?.decision?.issue_available_investors_type ===
            IssueAvailableInvestorsType.qualified_and_unqualified
        ) {
            setIsOnlyDfa(true)
        }

        if (
            dfa?.decision?.issue_available_investors_type_limits ===
            IssueAvailableInvestorsType.qualified_and_unqualified_with_limit
        ) {
            setIsOnlyDfa(true)
        }
    }

    const onChange = (val: IValues | null) => {
        if (val && 'acceptor' in val) {
            if ('total_price' in val.acceptor) {
                dispatch(setAccepterOffer({ price: val.acceptor.total_price }))
            }

            if ('amount_dfa' in val.acceptor) {
                dispatch(setAccepterOffer({ amount_dfa: val.acceptor.amount_dfa }))
            }
        }
    }

    const onCloseHandler = (type: 'dfa' | 'money') => {
        if (type === 'money') {
            const values = { ...form.getFieldsValue() }
            values.acceptor.total_price = undefined
            form.setFieldsValue(values)
            dispatch(setAccepterOffer({ price: undefined }))
        }

        if (type === 'dfa') {
            const values = { ...form.getFieldsValue() }
            values.acceptor.dfa_name = ''
            values.acceptor.dfa = ''
            values.acceptor.amount_dfa = ''
            form.setFieldsValue(values)
            dispatch(setAccepterOffer({ dfa: {}, amount_dfa: undefined }))
            return
        }
    }

    const preValidation = () => {
        if (!isDfaSelected) {
            openMessage({
                type: 'error',
                message:
                    'Направление Оферты без указания ЦФА недопустимо. Укажите ЦФА в качестве встречного предоставления как минимум с одной стороны.',
            })
        }

        return isDfaSelected
    }

    return (
        <Step
            stepIndex={2}
            onChangeValues={onChange}
            form={form}
            stepTitle="Встречное предоставление Акцептанта"
            allowEdit={false}
            saveAfterEdit
            disabledHeaderStepCounter={true}
            preValidation={preValidation}
        >
            <DfaAndPriceOffer
                onOpen={(val) => {
                    setIsOfferOpen(val)
                }}
                onClose={onCloseHandler}
                isOfferOpen={isOfferOpen}
                type="acceptor"
                isEdit={isEdit}
                onDfaChange={onDfaChangeHelper}
                currentClientType={form.getFieldValue('client_type')}
                currentBaseDescription={form.getFieldValue('base_description')}
                isOnlyDfa={isOnlyDfa}
            />
        </Step>
    )
}
