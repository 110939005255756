import { IFooter } from '@dltru/dfa-ui'
import { Button, CONTENT_FOOTER_ID, CaseDel, ContentFooterBox, Portal } from '@dltru/dfa-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { dfaTransferSelector } from '@store/dfa/ownershipTransfer/selectors'

interface ICComponentProps {
    status: IFooter['status']
    sendOferta: () => void
}
export const Footer: FC<ICComponentProps> = ({ status, sendOferta }) => {
    const contentFooter = document.getElementById(CONTENT_FOOTER_ID)
    const isLoading = useSelector(dfaTransferSelector.selectIsLoading)

    return (
        <>
            {contentFooter && typeof contentFooter !== null && (
                <Portal container={contentFooter}>
                    {status === 'precreate' && (
                        <ContentFooterBox>
                            <Button
                                icon={<CaseDel width="20px" height="20px" />}
                                borderRadius={16}
                                type="primary"
                                onClick={sendOferta}
                                disabled={isLoading}
                            >
                                Направить оферту
                            </Button>
                        </ContentFooterBox>
                    )}
                </Portal>
            )}
        </>
    )
}
